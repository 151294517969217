import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const ThreeCanvas = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        // Scene setup
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 100);
        camera.position.z = 5;

        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        // Particle setup
        const particlesGeometry = new THREE.BufferGeometry();
        const particlesCnt = 5000;

        const posArray = new Float32Array(particlesCnt * 3); // Multiply by 3 because there are 3 values (components) per vertex
        for (let i = 0; i < particlesCnt * 3; i++) {
            // posArray[i] = Math.random()
            // Randomize in space
            posArray[i] = (Math.random() - 0.5) * 10; // Spread in space (-2.5 to 2.5 range)
        }
        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

        // Material
        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.005,
            transparent: true,
            opacity: 0.6
        });

        const particleMesh = new THREE.Points(particlesGeometry, particlesMaterial);
        scene.add(particleMesh);

        // Animation
        let frameId;
        const fps = 30;
        const animate = function () {
            frameId = setTimeout(() => {
                requestAnimationFrame(animate);
                particleMesh.rotation.y += 0.001;
                renderer.render(scene, camera);
            }, 1000 / fps);
        };
        animate();

        // Debounce function
        // const debounce = (fn, ms) => {
        //     let timer;
        //     return () => {
        //         clearTimeout(timer);
        //         timer = setTimeout(() => {
        //             timer = null;
        //             fn();
        //         }, ms);
        //     };
        // };

        // Debounced resize function
        // const debouncedResize = debounce(() => {
        //     renderer.setSize(window.innerWidth, window.innerHeight);
        //     camera.aspect = window.innerWidth / window.innerHeight;
        //     camera.updateProjectionMatrix();
        // }, 250);

        // window.addEventListener('resize', debouncedResize);

        // Cleanup
        return () => {
            clearTimeout(frameId);
            // window.removeEventListener('resize', debouncedResize);
            mountRef.current.removeChild(renderer.domElement);
        }
    }, []);

    return <div ref={mountRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0, opacity: 0.5 }}></div>;
};

export default ThreeCanvas;
