import React from 'react';
import './Header.css'; // Make sure you have the necessary CSS for the header

const Header = () => {
    return (
        <>
            <header className="App-header glowing-text">
                <h1>[Vincent Pierlot]</h1>
                <p><i>Computer Science Student</i></p>
                <p><i>Cybersecurity Enthusiast</i></p>
            </header>
        </>
    );
};

export default Header;
