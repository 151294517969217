import React from 'react';
import './Body.css';

const Body = () => {
    return (
        <div className="centered-container">
            <a href="https://github.com/nobleb312" target="_blank" className="glowing-text">
                Github
            </a>
            <a href="https://www.linkedin.com/in/vincent-pierlot/" target="_blank" className="glowing-text">
                LinkedIn
            </a>
            <a href="https://drive.google.com/file/d/1VfM1NgMBsQih7-FG3fpnBrXfskjz3iFa/view?usp=sharing" target="_blank" className="glowing-text">
                My Resume
            </a>
        </div>
    );
};

export default Body;