import React from 'react';
import './App.css';
import Header from './Header';
import Body from './Body';
import ThreeCanvas from './ThreeCanvas';

function App() {
  return (
    <div className="App">
      <ThreeCanvas />
      <Header />
      <Body />
      {/* Other components will go here, like About, Portfolio, Contact, etc. */}

      {/* Example structure */}
      {/* <About /> */}
      {/* <Contact /> */}
      {/* <Footer /> */}
    </div>
  );
}



export default App;
